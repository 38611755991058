<template>
  <div class="my-6">
    <v-row justify="center" no-gutters>
      <v-col cols="11" lg="9">
        <v-row>
          <v-col md="6" cols="12">
            <div>
              <v-img contain class="full-width px-16 py-4 productDetail-image-box" :src="currentImage" alt="" aspect-ratio="1:1" />
            </div>
            <div class="mt-4" v-if="product.gallery != undefined && product.gallery.length > 0">
              <v-row justify="center" align="center" no-gutters>
                <v-col cols="auto">
                  <v-icon class="cursor-pointer" v-text="'$chevronLeftIcon'" large @click="goPrevious"></v-icon>
                </v-col>
                <v-col cols="9">
                  <div class="mb-5">
                    <carousel v-if="product.gallery.length > 0" ref="carousel" :margin="25" :items="4" :dots="false" :nav="false" :mouseDrag="false" :loop="false">
                      <template v-for="(image, index) in product.gallery">
                        <div
                          :class="currentImage == image ? 'productDetail-gallery-active' : 'productDetail-gallery'"
                          class="text-center cursor-pointer"
                          :key="`galleryImage_${index}`"
                          @mouseenter="() => (currentImage = image)"
                        >
                          <v-card title elevation="0">
                            <img class="productDetail-gallery-image mx-auto" :src="image" alt="" />
                          </v-card>
                        </div>
                      </template>
                    </carousel>
                  </div>
                </v-col>
                <v-col cols="auto">
                  <v-icon class="cursor-pointer" v-text="'$chevronRightIcon'" large @click="goNext"></v-icon>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col md="6" cols="12">
            <div class="font-weight-bold text-md-h4 text-h5">{{ product.name }}</div>
            <v-row
              align="center"
              justify="space-between"
              no-gutters
              v-if="product.highestSellingPrice == product.lowestSellingPrice"
              :class="product.highestDiscountedRate != 0 ? 'd-visible' : 'd-invisible'"
            >
              <v-col cols="5">
                <div class="text-caption text-decoration-line-through text_light_gray--text">
                  {{ $shared.formatCurrency(product.highestSellingPrice) }}
                </div>
              </v-col>
              <v-col cols="2" sm="1" md="2" lg="2" xl="1" :class="product.highestDiscountedRate >= 3 ? 'd-visible' : 'd-invisible'">
                <div class="productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text">
                  <span class="white--text text-caption text-uppercase font-weight-bold">- {{ product.highestDiscountedRate }}%</span>
                </div>
              </v-col>
            </v-row>

            <v-row
              align="center"
              justify="space-between"
              no-gutters
              v-else-if="selectedSku == ''"
              :class="product.highestDiscountedRate != 0 || product.lowestDiscountedRate != 0 ? 'd-visible' : 'd-invisible'"
            >
              <v-col cols="5">
                <div class="text-caption text-decoration-line-through text_light_gray--text">
                  {{ `${$shared.formatCurrency(product.lowestSellingPrice)} - ${$shared.formatCurrency(product.highestSellingPrice)}` }}
                </div>
              </v-col>
              <v-col cols="2" sm="1" md="2" lg="2" xl="1" :class="product.highestDiscountedRate >= 3 || product.lowestDiscountedRate >= 3 ? 'd-visible' : 'd-invisible'">
                <div class="productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text">
                  <span class="white--text text-caption text-uppercase font-weight-bold px-1 text-center">
                    {{ $t(`label.upTo`) }}
                    <br />
                    {{ product.highestDiscountedRate }}%
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row align="center" justify="space-between" no-gutters v-else :class="((marketPrice - productPrice) / marketPrice) * 100 != 0 ? 'd-visible' : 'd-invisible'">
              <v-col cols="5">
                <div class="text-caption text-decoration-line-through text_light_gray--text">
                  {{ $shared.formatCurrency(marketPrice) }}
                </div>
              </v-col>
              <v-col cols="2" sm="1" md="21" lg="2" xl="1" :class="Math.floor(((marketPrice - productPrice) / marketPrice) * 100) >= 3 ? 'd-visible' : 'd-invisible'">
                <div class="productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text">
                  <span class="white--text text-caption text-uppercase font-weight-bold">- {{ Math.floor(((marketPrice - productPrice) / marketPrice) * 100) }}%</span>
                </div>
              </v-col>
            </v-row>

            <div class="d-flex justify-space-between align-center">
              <div>
                <div class="font-weight-bold text-md-h4 text-h5 unbox_red--text my-3 highestDiscountedPrice" v-if="product.highestDiscountedPrice == product.lowestDiscountedPrice">
                  {{ `${$shared.formatCurrency(product.highestDiscountedPrice)}` }}
                </div>
                <div class="font-weight-bold text-md-h4 text-h5 unbox_red--text my-3 lowestDiscountedPrice" v-else-if="selectedSku == ''">
                  {{ `${$shared.formatCurrency(product.lowestDiscountedPrice)} - ${$shared.formatCurrency(product.highestDiscountedPrice)}` }}
                </div>
                <div class="font-weight-bold text-md-h4 text-h6 unbox_red--text my-3 productPrice" v-else>
                  {{ `${$shared.formatCurrency(productPrice)}` }}
                </div>
              </div>
              <div v-if="isOutOfStock">
                <div class="pinky-box text-uppercase">{{ $t('label.soldOut') }}</div>
              </div>
            </div>

            <div class="text-caption text-justify" v-if="product.description != ''">{{ product.description }}</div>
            <div class="my-3 gray--text text-body-2" v-if="product.insuranceType">
              {{ `${$t(`label.aeonCreditInstallmentPlan`, [$shared.formatCurrency(aeonInstallmentPrice)])}` }}
              <v-icon small v-text="'$exclamationMarkIcon'" class="stroke--link_text cursor-pointer" @click="openAeonForm"></v-icon>
            </div>
            <div v-if="product.variants != undefined && product.variants.length > 0 && variantOptions.length > 0">
              <v-divider class="my-3"></v-divider>
              <div class="font-weight-bold text-body-2 my-3">{{ $t(`label.variations`) }}</div>
              <div>
                <v-row v-for="option in variantOptions.filter((opt) => !opt.data.every((o) => o.disabled))" :key="`${option.key}`" justify="space-between" no-gutters>
                  <v-col cols="12" sm="auto" class="text-sm-body-1 text-body-2 pt-4">
                    <span>{{ option.key }}</span>
                  </v-col>
                  <v-col cols="12" sm="9" class="mb-4">
                    <v-select
                      :value="selectedVariant.find((x) => x.key == option.key) != undefined ? selectedVariant.find((x) => x.key == option.key).value : ''"
                      filled
                      class="rounded-pill productVariant-field pl-sm-4 pl-0 text-sm-body-1 text-body-2"
                      :placeholder="$t(`label.pleaseSelect`)"
                      :rules="$validation.dropDownListRules()"
                      :items="option.data.filter((o) => !o.disabled)"
                      @change="updateSelecedVariant(option.key, $event)"
                      @click:clear="updateSelecedVariant(option.key, '')"
                      append-icon="$chevronDownIcon"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="red--text text-body-2 mt-4" v-if="noSelectVariantError">{{ $t(`label.pleaseSelectProductVariant`) }}</div>
            <v-divider class="my-3 mt-6"></v-divider>

            <v-row no-gutters align="top" class="mb-2" v-if="product.insuranceType && productPrice < 8000.01 && productPrice > 0">
              <v-col cols="auto"><v-checkbox class="mt-0" hide-details v-model="addOnInsurance"></v-checkbox></v-col>
              <v-col>
                <p class="mb-0">
                  {{ $t(`label.insurancePlan`, [$shared.formatCurrency(getInsuranceTierPrice())]) }}
                  <span class="link-text cursor-pointer" @click="openProtectionPlanPage">
                    {{ $t(`label.tncsApply`) }}
                    <v-icon small v-text="'$exclamationMarkIcon'" class="stroke--link_text"></v-icon>
                  </span>
                </p>
              </v-col>
              <v-col cols="12" class="py-2">
                <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(getInsuranceTierPrice()) }}</div>
              </v-col>
            </v-row>
            <!---->
            <div v-if="isOutOfStock">
              <div>
                <span class="pinky-box">{{ $t('label.note') }}:</span>
              </div>
              <div class="gray--text mt-4 mb-16 text-body-2">{{ $t('label.weAreSorrySoldOut') }}</div>

              <v-btn block color="black" class="rounded-lg font-weight-bold text-md-h6 text-body-2 py-md-8 py-6 white--text" @click="contactUnboxWhatsapp">
                {{ $t(`label.contactUnbox`) }}
              </v-btn>
            </div>
            <div v-else-if="productStock !== null && productStock.display_sku == this.selectedSku && productStock.quantity <= 0 && isPreOrderItem">
              <div class="mt-6">
                <v-btn outlined block color="black" class="rounded-lg font-weight-bold ext-md-h6 text-body-2 py-md-8 py-6">
                  {{ $t(`label.soldOut`) }}
                </v-btn>
              </div>
            </div>
            <!---->
            <div v-else>
              <div class="mt-6" v-if="!isPreOrderItem">
                <v-btn outlined :disabled="productStock.quantity === 0" block color="black" class="rounded-lg font-weight-bold text-md-h6 text-body-2 py-md-8 py-6" @click="addToCart">
                  {{ $t(`label.addToCart`) }}
                </v-btn>
              </div>
              <div class="mt-6">
                <v-btn
                  block
                  :disabled="!isPreOrderItem && productStock.quantity === 0"
                  color="unbox_red"
                  class="rounded-lg font-weight-bold white--text text-md-h6 text-body-2 py-md-8 py-6"
                  @click="buyNow"
                >
                  {{ isPreOrderItem ? $t(`label.preOrderNow`) : $t(`label.buyNow`) }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import carousel from 'xx-vue-owl-carousel'
import { debounce } from 'lodash'
import { STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU } from '@/store/stock.module'
import { PRODUCT_PRODUCT_BY_GROUP } from '@/store/product.module'
import { SHARED, SESSION, PRODUCT_TYPE, EXTERNAL_PRODUCT } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { sharedHelper } from '@/utils'
import { PRODUCT_STATUS } from '@/constants/enums'
import { ORDER_ADD_CART_ITEM, ORDER_CREATE_CART, ORDER_UPDATE_CART_ITEM } from '@/store/order.module'

export default {
  name: 'ShopProduct',
  components: { carousel },
  beforeRouteLeave(to, from, next) {
    const isNotShopRelatedPage = ![ROUTE_NAME.SHOP, ROUTE_NAME.SHOP_PRODUCT].includes(to.name)
    if (isNotShopRelatedPage) {
      localStorage.removeItem(SESSION.SHOP_SEARCH)
    }
    next()
  },
  data: () => {
    const groupId = {
      development: [
        { id: '349', depositAmount: '500' },
        { id: '350', depositAmount: '500' },
        { id: '376', depositAmount: '500' }
      ],
      staging: [
        { id: '239', depositAmount: '500' },
        { id: '240', depositAmount: '500' },
        { id: '241', depositAmount: '500' },
        { id: '242', depositAmount: '500' },
        { id: '243', depositAmount: '0' }
      ],
      production: [
        { id: '587', depositAmount: '0' },
        { id: '588', depositAmount: '0' },
        { id: '589', depositAmount: '0' },
        { id: '590', depositAmount: '0' }
      ]
    }
    return {
      item: null,
      isPreOrderItem: false,
      aeonInstallmentPrice: 0,
      noSelectVariantError: false,
      productGroupId: '',
      productNotFound: false,
      currentImage: '',
      productPrice: '',
      marketPrice: '',
      allVariantOptions: [],
      selectedVariant: [], // array of {variant categories, skus}
      selectedSku: '',
      variantOptions: [],
      skuImage: '',
      addOnInsurance: false,
      debouncedQuantityToAdd: 0,
      depositProduct: groupId[process.env.NODE_ENV],
      preorderDeposit: 0
    }
  },
  created() {
    this.checkRouteParam()
  },
  computed: {
    productStock() {
      return this.$store.state.stock?.stock ?? {}
    },
    product() {
      return this.$store.state.product.product
    },
    isLoggedIn() {
      return this.$store.state.customer.isLoggedIn
    },
    cartUuid() {
      return this.$store.state.order.cart.uuid
    },
    customerInfo() {
      return this.$store.state.customer.info
    },
    existingCartItem() {
      return this.$store.state.order.cart.items.find((i) => i.productDisplaySku === this.selectedSku) ?? null
    },
    isOutOfStock() {
      return !!this.selectedSku && !this.isPreOrderItem && this.productStock.quantity === 0
    }
  },
  watch: {
    product() {
      this.variantOptions = this.$store.state.product.product.variantOptions
      this.allVariantOptions = this.$store.state.product.product.optionData

      this.variantOptions.forEach((option) => {
        this.selectedVariant.push({
          key: option.key,
          value: null,
          skus: []
        })
      })
      this.selectedVariant.forEach((x) => {
        x.skus = this.product.allAvailableSku
      })

      /* NO VARIANT */
      if (this.$store.state.product.product.variants.length == 1) {
        this.selectedSku = this.$store.state.product.product.variants[0].sku
        let productInfo = this.product.variants.find((x) => x.sku == this.selectedSku)
        this.productPrice = productInfo.discountedPrice
        this.marketPrice = productInfo.marketPrice
        let data = {
          displaySku: this.selectedSku
        }
        if (productInfo.status != PRODUCT_STATUS.NEW_RELEASE) {
          this.isPreOrderItem = false
          this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU, { data })
        } else {
          this.isPreOrderItem = true
        }
      }

      this.currentImage = this.$store.state.product.product.gallery[0]
      this.aeonInstallmentPrice = sharedHelper.calculateAeonLoanInstallment(this.product.lowestDiscountedPrice, this.addOnInsurance, this.getInsuranceTierPrice())
    },
    productPrice(newValue) {
      if (newValue > 8000 || newValue == 0) {
        this.addOnInsurance = false
      } else {
        this.addOnInsurance = this.product.insuranceType ?? ''
      }
    },
    selectedSku() {
      if (this.selectedSku != '') {
        this.skuImage = `${SHARED.MEDIA_SERVER_URL}/products/variants/${this.selectedSku}.png`
        let productInfo = this.product.variants.find((x) => x.sku == this.selectedSku)
        this.currentImage = this.skuImage
        let data = {
          displaySku: this.selectedSku
        }
        if (productInfo.status != PRODUCT_STATUS.NEW_RELEASE) {
          this.isPreOrderItem = false
          this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU, { data })
        } else {
          this.isPreOrderItem = true
        }
      }
    },
    $route(from, to) {
      this.checkRouteParam()
    },
    addOnInsurance() {
      if (this.selectedSku != '') {
        let productInfo = this.product.variants.find((x) => x.sku == this.selectedSku)
        this.aeonInstallmentPrice = sharedHelper.calculateAeonLoanInstallment(productInfo.discountedPrice, this.addOnInsurance, this.getInsuranceTierPrice())
      } else {
        this.aeonInstallmentPrice = sharedHelper.calculateAeonLoanInstallment(this.product.lowestDiscountedPrice, this.addOnInsurance, this.getInsuranceTierPrice())
      }
    }
  },
  methods: {
    getInsuranceTierPrice() {
      if (this.selectedSku == '') {
        let tierInsurance = EXTERNAL_PRODUCT.INSURANCE[this.product.insuranceType.toUpperCase()].tier.find(
          (x) => x.minCoverPrice <= this.product.lowestDiscountedPrice && x.maxCoverPrice >= this.product.lowestDiscountedPrice
        )
        if (tierInsurance != undefined) {
          return tierInsurance.unit_price
        }
      } else {
        let product = this.product.variants.find((x) => x.sku == this.selectedSku)
        let tierInsurance = EXTERNAL_PRODUCT.INSURANCE[this.product.insuranceType.toUpperCase()].tier.find(
          (x) => x.minCoverPrice <= product.discountedPrice && x.maxCoverPrice >= product.discountedPrice
        )
        if (tierInsurance != undefined) {
          return tierInsurance.unit_price
        }
      }
    },
    openProtectionPlanPage() {
      let route = this.$router.resolve({
        name: ROUTE_NAME.PROTECTION_PLAN
      })
      window.open(route.href, '_blank')
    },
    openAeonForm() {
      window.open('https://form.unbox.com.my/aeon-credit/info', '_blank')
    },
    updateSelecedVariant(key, value) {
      this.noSelectVariantError = false
      let isSetValueToNull = false
      if (this.selectedVariant.find((x) => x.key == key) == undefined) {
        this.selectedVariant.push({
          key: key,
          value: value,
          skus: this.allVariantOptions
            .filter((y) => y.name == key && y.value == value)
            .map((o) => {
              return o.sku
            })
        })
      } else {
        if (this.selectedVariant.find((x) => x.key == key).value != value && value !== null) {
          this.selectedVariant = this.selectedVariant.filter((x) => x.key != key)
          this.selectedVariant.push({
            key: key,
            value: value,
            skus: this.allVariantOptions
              .filter((y) => y.name == key && y.value == value)
              .map((o) => {
                return o.sku
              })
          })
        } else {
          this.selectedVariant = this.selectedVariant.filter((x) => x.key != key)
          isSetValueToNull = true
        }
      }

      /* RESET ALL */
      this.variantOptions.forEach((y) => {
        y.data.forEach((z) => {
          return (z.disabled = false)
        })
      })

      let commonSku = []
      let actualSelectedVariant = this.selectedVariant.filter((x) => x.value !== null && x.value != '')

      let actualSelectedVariantMatrix = new Array(actualSelectedVariant.length)
      this.selectedVariant
        .filter((x) => x.value !== null && x.value != '')
        .forEach((x, i) => {
          actualSelectedVariantMatrix[i] = x.skus
        })

      commonSku = this.intersection(actualSelectedVariantMatrix)

      for (let i = 0; i < this.selectedVariant.length; i++) {
        let nextPossibleSku = this.selectedVariant[i].skus

        this.variantOptions
          .filter((x) => x.key != this.selectedVariant[i].key)
          .forEach((option) => {
            option.data.forEach((option) => {
              let found = this.intersection([option.sku, nextPossibleSku])
              option.disabled = !(found.length > 0)
            })
          })
      }
      if (commonSku.length == 1) {
        let commonSkuInfo = this.product.variants.find((x) => x.sku == commonSku[0])

        if (!isSetValueToNull) {
          let tempSelectecVariant = []
          commonSkuInfo.option.forEach((x) => {
            tempSelectecVariant.push({
              key: x.name,
              value: x.value,
              skus: this.allVariantOptions
                .filter((y) => y.name == x.name && y.value == x.value)
                .map((o) => {
                  return o.sku
                })
            })
            this.selectedVariant = tempSelectecVariant
          })

          for (let i = 0; i < this.selectedVariant.length; i++) {
            let nextPossibleSku = this.selectedVariant[i].skus

            this.variantOptions
              .filter((x) => x.key != this.selectedVariant[i].key)
              .forEach((data) => {
                data.data.forEach((option) => {
                  let found = this.intersection([option.sku, nextPossibleSku])
                  option.disabled = !(found.length > 0)
                })
              })
          }
        }

        this.selectedSku = commonSkuInfo.sku
        this.productPrice = commonSkuInfo.discountedPrice
        this.marketPrice = commonSkuInfo.marketPrice
        this.aeonInstallmentPrice = sharedHelper.calculateAeonLoanInstallment(commonSkuInfo.discountedPrice, this.addOnInsurance, this.getInsuranceTierPrice())
        //newinsu
      } else {
        if (commonSku.length === 0) {
          // clear first selected variant if current variant selections result in no available SKU
          this.selectedVariant.shift()
        }
        this.aeonInstallmentPrice = sharedHelper.calculateAeonLoanInstallment(this.product.lowestDiscountedPrice, this.addOnInsurance, this.getInsuranceTierPrice())
        this.selectedSku = ''
      }
    },
    async addToCart() {
      if (this.selectedSku == '') {
        this.noSelectVariantError = true
      } else if (!this.isLoggedIn) {
        this.$root.$master.openLogin()
      } else {
        await this.$root.$master.updateCartInfo()
        if (!this.cartUuid) {
          await this.createCart()
        } else {
          this.debouncedQuantityToAdd++
          if (this.existingCartItem) {
            if (this.productStock.quantity <= this.existingCartItem.quantity) {
              this.$root.$master.showSnackbarMessage(this.$t(`message.alreadyMaxStockInCart`))
              return
            }
            await this.increaseCartItemQuantity(this.existingCartItem)
          } else {
            await this.addItemToCart()
          }
        }
      }
    },
    buyNow() {
      this.preorderDeposit = this.depositProduct.find((x) => x.id == this.productGroupId) == undefined ? 0 : Number(this.depositProduct.find((x) => x.id == this.productGroupId).depositAmount)
      if (this.selectedSku == '') {
        this.noSelectVariantError = true
      } else if (!this.isLoggedIn) {
        this.$root.$master.openLogin()
      } else {
        if (!this.isPreOrderItem || this.preorderDeposit != 0) {
          this.purhcaseNow()
        } else {
          this.preOrderNow()
        }
      }
    },
    preOrderNow() {
      localStorage.removeItem(SESSION.BUYNOW_CART)
      let product = this.product.variants.find((x) => x.sku == this.selectedSku)
      sharedHelper.addToBuyNowCart(this.product.name, product, PRODUCT_TYPE.PRODUCT, 1, this.product.group, this.product.insuranceType, this.addOnInsurance)
      this.$router.push({
        name: ROUTE_NAME.SHOP_PREORDER_CHECKOUT,
        params: {
          action: 'buynow'
        }
      })
    },
    purhcaseNow() {
      localStorage.removeItem(SESSION.BUYNOW_CART)
      let product = this.product.variants.find((x) => x.sku == this.selectedSku)
      sharedHelper.addToBuyNowCart(this.product.name, product, PRODUCT_TYPE.PRODUCT, 1, this.product.group, this.product.insuranceType, this.addOnInsurance)
      this.$router.push({
        name: ROUTE_NAME.SHOP_CHECKOUT,
        params: {
          action: 'buynow',
          depositAmount: this.preorderDeposit,
          isDeposit: this.preorderDeposit != 0 ? true : false
        }
      })
    },
    async checkRouteParam() {
      let param = this.$route.params.productName.split('-')
      if (!param[param.length - 1].includes(`${SHARED.PRODUCT_GROUP_PREFIX}`)) {
        this.productNotFound = true
      } else {
        this.productGroupId = param[param.length - 1].replace(`${SHARED.PRODUCT_GROUP_PREFIX}`, '')
        this.getProductDetail()
      }
    },
    async getProductDetail() {
      let filter = {
        groupId: this.productGroupId,
        preOrder: this.$route.params.preOrder
      }

      this.$store.dispatch(PRODUCT_PRODUCT_BY_GROUP, { filter })
    },
    goNext() {
      this.$refs.carousel.goNext()
    },
    goPrevious() {
      this.$refs.carousel.goPrev()
    },
    intersection() {
      let result = []
      let lists

      if (arguments.length === 1) {
        lists = arguments[0]
      } else {
        lists = arguments
      }

      for (let i = 0; i < lists.length; i++) {
        let currentList = lists[i]
        for (let y = 0; y < currentList.length; y++) {
          let currentValue = currentList[y]
          if (result.indexOf(currentValue) === -1) {
            let existsInAll = true
            for (let x = 0; x < lists.length; x++) {
              if (lists[x].indexOf(currentValue) === -1) {
                existsInAll = false
                break
              }
            }
            if (existsInAll) {
              result.push(currentValue)
            }
          }
        }
      }
      return result
    },
    async createCart() {
      const addOnServices = []
      if (this.addOnInsurance) {
        const insuranceTier = EXTERNAL_PRODUCT.INSURANCE[this.product.insuranceType.toUpperCase()].tier.find(
          (x) => x.minCoverPrice <= this.product.lowestDiscountedPrice && x.maxCoverPrice >= this.product.lowestDiscountedPrice
        )
        addOnServices.push({
          name: insuranceTier.name,
          type: 'Insurance',
          display_sku: insuranceTier.display_sku,
          quantity: 1
        })
      }
      const requestBody = {
        email: localStorage.getItem(SESSION.EMAIL),
        phoneNumber: `${this.customerInfo.phoneCode}${this.customerInfo.phone}`,
        customerName: `${this.customerInfo.firstName} ${this.customerInfo.lastName}`,
        discountCode: '',
        discountPercentage: 0,
        discountAmount: 0,
        markUpPrice: 0,
        markUpPercentage: 0,
        remark: '',
        items: [
          {
            displaySku: this.selectedSku,
            quantity: 1,
            discountCode: '',
            discountPercentage: 0,
            discountAmount: 0,
            markUpPrice: 0,
            markUpPercentage: 0,
            productType: PRODUCT_TYPE.PRODUCT,
            remark: '',
            services: addOnServices,
            productRemark: ''
          }
        ]
      }
      await this.$store.dispatch(ORDER_CREATE_CART, { data: requestBody })
      this.$root.$master.updateCartInfo()
    },
    addItemToCart: debounce(async function () {
      const itemStockQuantity = this.productStock?.quantity ?? 0
      const quantityToAdd = Math.min(this.debouncedQuantityToAdd, itemStockQuantity)
      const addOnServices = []
      if (this.addOnInsurance) {
        const insuranceTier = EXTERNAL_PRODUCT.INSURANCE[this.product.insuranceType.toUpperCase()].tier.find(
          (x) => x.minCoverPrice <= this.product.lowestDiscountedPrice && x.maxCoverPrice >= this.product.lowestDiscountedPrice
        )
        addOnServices.push({
          name: insuranceTier.name,
          type: 'Insurance',
          display_sku: insuranceTier.display_sku,
          quantity: quantityToAdd
        })
      }
      const requestBody = {
        uuid: this.cartUuid,
        items: [
          {
            displaySku: this.selectedSku,
            quantity: quantityToAdd,
            discountCode: '',
            discountPercentage: 0,
            discountAmount: 0,
            markUpPrice: 0,
            markUpPercentage: 0,
            productType: PRODUCT_TYPE.PRODUCT,
            remark: '',
            services: addOnServices,
            productRemark: ''
          }
        ]
      }
      await this.$store.dispatch(ORDER_ADD_CART_ITEM, { data: requestBody })
      this.$root.$master.updateCartInfo()
    }, 500),
    increaseCartItemQuantity: debounce(async function (item) {
      const itemStockQuantity = this.productStock?.quantity ?? 0
      const quantityToAdd = Math.min(this.debouncedQuantityToAdd, itemStockQuantity - item.quantity)
      const addOnServices = []
      if (item.requiredInsurance || this.addOnInsurance) {
        addOnServices.push({
          name: item.insurance.name,
          type: item.insurance.type,
          display_sku: item.insurance.display_sku,
          quantity: this.addOnInsurance ? item.insurance.quantity + quantityToAdd : item.insurance.quantity
        })
      }

      if (item.quantity <= itemStockQuantity) {
        const requestBody = {
          itemUuid: item.uuid,
          cartUuid: item.cartUuid,
          quantity: item.quantity + quantityToAdd,
          discountCode: '',
          discountPercentage: 0,
          discountAmount: 0,
          markUpPrice: 0,
          markUpPercentage: 0,
          services: addOnServices,
          remark: ''
        }
        await this.$store.dispatch(ORDER_UPDATE_CART_ITEM, { data: requestBody })
        this.$root.$master.updateCartInfo()
      }
      this.debouncedQuantityToAdd = 0
    }, 500),
    contactUnboxWhatsapp() {
      window.open('https://api.whatsapp.com/send/?phone=601155550000&text&app_absent=0')
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.productName !== from.params.productName) {
      this.selectedSku = ''
    }
    next()
  }
}
</script>
<style lang="scss" scoped>
.productDetail-image-box {
  height: 520px;
  border: 1px solid var(--v-light_gray-base);
  min-height: 320px;
}

.productDetail-gallery {
  border: 1px solid transparent;
}

.productDetail-gallery-active {
  border: 1px solid var(--v-unbox_primary-base);
}

.pinky-box {
  background-color: #f87171;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  white-space: nowrap;
}

.productContent-discountRate {
  background-color: var(--v-sharp_red-base);
}

@media only screen and (max-width: 959px) {
  .productDetail-image-box {
    margin-top: 50px;
    height: auto;
  }
}
</style>
